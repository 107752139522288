import format from "date-fns/format";
import moment from "moment";
import { LOCATION_PREFIXES_REGEX } from "./Constants";
export const convertDollarsToCents = (price) => (price * 100).toFixed(0);

export const convertCentsToDollars = (price) => (price / 100).toFixed(2);

export const formatProductDate = (date) => format(date, "MMM Do, YYYY");

export const formatOrderDate = (date) =>
  format(date, "ddd h:mm A, MMM Do, YYYY");

export const _findPromotions = (data, tab) => {
  let rows = data;
  if (data && data.length) {
    if (tab === 0) {
      rows = data.filter((item) => {
        return (
          _getStartOf().diff(_getStartOf(item.startdate), "days") >= 0 &&
          _getStartOf().diff(_getStartOf(item.enddate), "days") < 0
        );
      });
    } else if (tab === 1) {
      rows = data.filter((item) => {
        return _getStartOf(item.startdate).diff(_getStartOf(), "days") > 0;
      });
    } else {
      rows = data.filter((item) => {
        return _getStartOf(item.enddate).diff(_getStartOf(), "days") <= 0;
      });
    }
  }
  return rows;
};

export const _findBooking = (data, tab) => {
  let rows = data;
  if (data && data.length) {
    if (tab === 0) {
      rows = data.filter((item) => {
        return _getStartOf(item.startdate).diff(_getStartOf(), "days") > 0;
      });
    } else {
      rows = data.filter((item) => {
        return _getStartOf(item.enddate).diff(_getStartOf(), "days") <= 0;
      });
    }
  }
  return rows;
};

export const _formatDate = (
  date,
  format = "MMM DD, YYYY",
  showTime = false
) => {
  let formattedDate = moment(date).format(format);
  return formattedDate;
};

export const _getStartOf = (date = moment()) => moment(date).startOf("day");

export const getCurrencySymbolByCompany = (company) => {
  const companyCurrency = company.currency;
  switch (companyCurrency) {
    case "GBP":
      return "£";
    case "CAD":
      return "$";
    case "USD":
      return "$";
    case "AUD":
      return "$";
    default:
      return "$";
  }
};

export const stripLocationPrefix = (locationId = "") =>
  locationId?.replace(LOCATION_PREFIXES_REGEX, "");

export const getLocationIdParts = (locationId = "") => [
  locationId.substring(2),
  stripLocationPrefix(locationId),
];
export const mod = (n, m) => ((n % m) + m) % m;
